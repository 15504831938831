import React from 'react'
import "./footer.css"
const Footer = () => {
  return (
    <footer className='footer'>
        <div className="footer--container container">
            <h1 className="footer--title">
                Gherghouch Abdessalam
            </h1>
            <ul className="footer--list">
                <li>
                    <a href="#about" className="footer--link">
                        About
                    </a>
                </li>
                <li>
                    <a href="#about" className="footer--link">
                        Skills
                    </a>
                </li>
                <li>
                    <a href="#skills" className="footer--link">
                        Services
                    </a>
                </li>
            </ul>

           <div className="footer--social">

                <a href="https://www.instagram.com/abde0_011/?hl=fr" className="footer--social--icon" target="_blank">
                     <i class="uil uil-instagram"></i> 
                </a>

      
                <a href="https://www.linkedin.com/in/abdessalam-gherghouch-51084b23a/" className="footer--social--icon" target="_blank">
                    <i class="uil uil-linkedin"></i>
                </a>
                
            </div> 
            <div className="footer--copyright">
                &#169; DDOS0__0 Coder, All right reserved
            </div>
        </div>
    </footer>
  )
}

export default Footer
